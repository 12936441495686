
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Skeleton from '@components/atoms/Skeleton';
import Page from '@components/organisms/Page';
import LINKS from '@constants/links';
import CanonicalLink from '@features/seo/CanonicalLink';
import CmsHead from '@features/seo/CmsHead';
import createSwrFallback, { frequentRevalidationInterval } from '@helpers/fallback';
import useCmsPage from '@hooks/useCmsPage';
import useLoginSearchParam from '@hooks/useLoginSearchParam';
import { NextPage } from 'next';
const Skeletons = () => (<>
    <Skeleton type="rect" height={50} $style={{ margin: '0' }}/>
    <Skeleton type="rect" height={250} $style={{ margin: '0' }}/>
    <Skeleton type="rect" height={500} $style={{ margin: '0' }}/>
    <Skeleton type="rect" height={500} $style={{ margin: '0' }}/>
    <Skeleton type="rect" height={250} $style={{ margin: '0' }}/>
    <Skeleton type="rect" height={250} $style={{ margin: '0' }}/>
    <Skeleton type="rect" height={250} $style={{ margin: '0' }}/>
  </>);
const Index: NextPage<any> = ({ cmsPageId, canonicalUrl }) => {
    const { cmsPage } = useCmsPage(cmsPageId);
    useLoginSearchParam();
    if (!cmsPage) {
        return <Skeletons />;
    }
    return (<>
      <CmsHead cmsPageId={cmsPageId}/>
      <CanonicalLink url={canonicalUrl}/>
      <Page cmsPage={cmsPage}/>
    </>);
};
export default Index;
// const translations = [...NAMESPACE_REQUIRED.TOOLBAR, ...NAMESPACE_REQUIRED.FOOTER, 'promotions'];
const cmsPageId = 'HemkopStartPage';
async function getStaticProps() {
    const canonicalUrl = `${process.env.SERVER_API_HOST}${LINKS.START}`;
    const fallback = await createSwrFallback({ pageType: 'ContentPage', label: cmsPageId });
    return {
        props: {
            fallback,
            cmsPageId,
            canonicalUrl,
        },
        revalidate: frequentRevalidationInterval,
    };
}

    async function __Next_Translate__getStaticProps__195d779ee9c__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195d779ee9c__ as getStaticProps }
  