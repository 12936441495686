import { openLoginModal } from '@actions/login';
import useAppRouter from '@hooks/useAppRouter';
import { useAppDispatch } from '@hooks/redux';
import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';


export default function useLoginSearchParam() {
  const { replace } = useAppRouter();
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const pathname = usePathname() ?? '';
  const login = searchParams?.get('login');

  useEffect(() => {
    if (login === 'true') {
      dispatch(openLoginModal())
      // remove login from searchParams
      replace(pathname)
    }
  }, [login]);
}